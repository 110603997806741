import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Masthead from '../components/Masthead';
import SignUpForm from '../components/SignUpForm';

const IndexPage = () => (
    <Layout>
        <SEO title="Home" />
        <Masthead />
        <SignUpForm />
    </Layout>
);

export default IndexPage;

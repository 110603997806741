import React from 'react';
import WonderbookLogo from '../svg/Wonderbook-Logo.svg';
import ArrowDown from '../svg/arrow-down.svg';

import CloudIcon from '../svg/cloud.svg';
import Bird1 from '../svg/bird_1.svg';
import Bird2 from '../svg/bird_2.svg';
import Bird3 from '../svg/bird_3.svg';
import Doodle1 from '../svg/doodle_1.svg';
import Doodle2 from '../svg/doodle_2.svg';
import Doodle3 from '../svg/doodle_3.svg';

import wonderbook from '../images/wonderbook.png';
import wonderbook2x from '../images/wonderbook@2x.png';

const scrollToSignUp = (e) => {
    e && e.preventDefault();
    document.querySelector('#sign-up').scrollIntoView({
        behavior: 'smooth'
    });
};

const Masthead = () => (
    <section className="masthead bg-aqua pt-xs-6 pt-md-12 pb-lg-48 pb-xs-12 pb-md-64">
        <div className="container">
            <WonderbookLogo className="logo mb-xs-12 mb-md-24 img-xs-fit img-lg-auto" />
            <div className="row">
                <div className="col-xs-12 col-lg-7 txt-center">
                    <div className="row middle-lg h-100">
                        <div>
                            <h1 className="mb-8 stack-above">Tell us what you think and save on Wonderbooks.</h1>
                            <div className="more">
                                <button className="btn btn-link" aria-label="Scroll to form">
                                    <ArrowDown onClick={scrollToSignUp} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-lg-4 col-lg-offset-1 last-lg bottom-md">
                    <img
                        className="float-lg-left img-xs-fit img-lg-auto"
                        src={wonderbook}
                        alt="Inside cover of an open Wonderbook"
                        srcSet={`${wonderbook2x} 2x`}
                    />
                </div>
            </div>
        </div>
        <CloudIcon className="cloud" />
        <Bird1 className="bird_1" />
        <Bird2 className="bird_2" />
        <Bird3 className="bird_3" />
        <Doodle1 className="doodle_1" />
        <Doodle2 className="doodle_2" />
        <Doodle3 className="doodle_3" />
    </section>
);

export default Masthead;

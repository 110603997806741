import React from 'react';
import classnames from 'classnames';
import { FaCheck } from 'react-icons/fa';

const StatusButton = ({
    success = null,
    disabled = false,
    label = 'Submit',
    successLabel = 'Success',
    onClick = undefined,
    type = 'submit',
    className = 'btn'
}) => (
    <button
        className={classnames(className, {
            'btn-success': success
        })}
        type={type}
        disabled={disabled}
        onClick={onClick}
    >
        {!success && label}
        {success && (
            <>
                <FaCheck /> {successLabel}
            </>
        )}
    </button>
);

export default StatusButton;

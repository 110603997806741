import { useFormik } from 'formik';
import React from 'react';
import { object, string } from 'yup';
import axios from 'axios';
import classnames from 'classnames';
import Input from './forms/Input';
import TextArea from './forms/TextArea';
import StatusButton from './StatusButton';

import Star1 from '../svg/star_1.svg';
import Star2 from '../svg/star_2.svg';
import Star3 from '../svg/star_3.svg';
import Star4 from '../svg/star_4.svg';

let jsonpAdapter = require('axios-jsonp');

const schema = object().shape({
    email_address: string().email().required('Email Address is required'),
    no_internet: string().required(
        "Please share how your library is supporting patrons who don't have internet access"
    ),
    new_offerings: string().required(
        'Please share which new product, service, or content you would like to offer your patrons'
    )
});

const SignUpForm = () => {
    const [formState, setFormState] = React.useState('unset');
    const [formError, setFormError] = React.useState('There was an error submitting the form.');

    const handleSend = async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        try {
            let _data = {
                EMAIL: values.email_address,
                INTERNET: values.no_internet,
                OFFERINGS: values.new_offerings,
                b_d42dd3222be324e804f48bcd4_0ab92470bb: ''
            };
            let params = Object.keys(_data)
                .map((key) => `${key}=${_data[key]}`)
                .join('&');
            let { data } = await axios.request({
                url: `https://findawayworld.us7.list-manage.com/subscribe/post-json?u=d42dd3222be324e804f48bcd4&id=0ab92470bb&${params}`,
                adapter: jsonpAdapter,
                callbackParamName: 'c',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                method: 'POST'
            });

            if (data.result === 'error') throw new Error(data.msg);

            setFormState('sent:success');
            setTimeout(() => {
                setSubmitting(false);
                resetForm();
            }, 3000);
        } catch (e) {
            setSubmitting(false);
            if (
                e.message.includes('is already subscribed to list') ||
                e.message.includes('too many recent signup requests')
            ) {
                setFormError(`${values.email_address} has already submitted this form.`);
            }
            setFormState('sent:error');
        }
    };
    const { values, errors, touched, handleSubmit, handleChange, handleBlur, isSubmitting } = useFormik({
        initialValues: {
            email_address: '',
            no_internet: '',
            new_offerings: ''
        },
        validationSchema: schema,
        onSubmit: handleSend
    });

    const form = (
        <>
            <h2 className="txt-center mb-24">Answer these questions to receive your promo code.</h2>
            <form className="row" onSubmit={handleSubmit}>
                <div className="col-xs-12">
                    <p className="mb-0 txt-bold">Question 1 of 3</p>
                    <Input
                        className="mb-16"
                        label="What is your email address?"
                        labelClass="mb-4"
                        id="email_address"
                        value={values.email_address}
                        error={errors.email_address}
                        touched={touched.email_address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </div>
                <div className="col-xs-12">
                    <p className="mb-0 txt-bold">Question 2 of 3</p>
                    <TextArea
                        className="mb-16"
                        label="How is your library supporting patrons who don't have internet access?"
                        labelClass="mb-4"
                        id="no_internet"
                        collapse
                        value={values.no_internet}
                        error={errors.no_internet}
                        touched={touched.no_internet}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </div>
                <div className="col-xs-12">
                    <p className="mb-0 txt-bold">Question 3 of 3</p>
                    <TextArea
                        className="mb-16"
                        label="What new product, service, or content would you like to offer your patrons that is not currently available?"
                        labelClass="mb-4"
                        id="new_offerings"
                        collapse
                        value={values.new_offerings}
                        error={errors.new_offerings}
                        touched={touched.new_offerings}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </div>
                <div className="col-xs-12 txt-center">
                    <StatusButton
                        label="Submit"
                        successLabel="Submitted"
                        success={formState === 'sent:success'}
                        disabled={isSubmitting || formState === 'sent:success'}
                        type="submit"
                        className="btn btn-primary rounded-pill px-12 mb-2"
                    />
                    {formState === 'sent:error' && (
                        <div className="flash flash-danger rounded-pill submission-error">
                            <p>{formError}</p>
                        </div>
                    )}
                </div>
            </form>
        </>
    );

    React.useEffect(() => {
        const activateDoodles = ($el) => {
            clearTimeout(timer2);
            clearTimeout(timer);

            $el.classList.add('active');
            const timer = setTimeout(() => {
                $el.classList.remove('active');
            }, 17000);
            const timer2 = setTimeout(() => {
                activateDoodles($el);
            }, 17500);
        };

        let $el = document.querySelector('.doodles');
        activateDoodles($el);
    }, []);

    return (
        <section id="sign-up" className="sign-up py-xs-12 py-md-24 doodles">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3">
                        {formState === 'sent:success' ? <Success /> : form}
                    </div>
                </div>
            </div>
            <Star1 className="doodle star-1" />
            <Star2 className="doodle star-2" />
            <Star3 className="doodle star-3" />
            <Star4 className="doodle star-4" />
        </section>
    );
};

const Success = () => {
    const [isActive, setIsActive] = React.useState(false);

    React.useEffect(() => {
        setIsActive(true);
    }, []);

    return (
        <div className={classnames('success', { active: isActive })}>
            <h2 className="txt-center mb-24">It's time to spread wonder.</h2>
            <p className="h4 txt-center">WB5ALA</p>
            <p className="txt-center">
                Use the code WB5ALA to save 5% on a Wonderbook order of $2,000 or more.
                <br />
                Code valid between 1/21/2021 and 3/1/2021.
            </p>
            <p className="txt-center">
                <em>Cannot be combined with any other offers.</em>
            </p>
            <div className="col-xs-12 txt-center mt-12">
                <a
                    href="https://shop.playaway.com/wonderbook/categories?utm_source=mw&utm_medium=mw&utm_campaign=wb"
                    className="btn btn-primary rounded-pill px-12 mb-2"
                    target="_blank"
                    rel="noreferrer"
                >
                    Shop Now
                </a>
            </div>
        </div>
    );
};

export default SignUpForm;
